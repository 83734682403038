<template>
  <v-container id="news-list" class="ma-0 pa-0" style="max-width:100%;">
    <div class="sticky" data-aos="zoom-in">
      <span class="ma-4 mt-0 font-poetsen contents-title">News</span>
      <span class="font-Yusei orange--text text--darken-2 contents-sub-title">最新情報</span>
    </div>
    <v-timeline
      align-top
      dense
      data-aos="zoom-in"
    >
      <v-timeline-item
        v-for="(news, i) in newsList"
        :key="i"
        :icon="news.icon"
        color="teal lighten-3"
        fill-dot
        style="overflow: hidden;"
      >
        <v-row class="pt-1">
          <v-col cols="4" class="px-0">
            <strong>{{news.date}}</strong>
          </v-col>
          <v-col class="pl-0 mr-2">
            <strong>{{news.title}}</strong>
            <div>
              <v-btn v-if="news.detail" text color="blue" @click="showDialog(news)">{{news.subTitle}}</v-btn>
              <div v-else class="text-caption">{{news.subTitle}}</div>
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <v-dialog
      v-model="dialog"
      width="90%"
    >
      <v-card>
        <v-card-title class="text-h5 teal white--text ">
          <!-- <v-icon dark large>{{dialogObj.icon}}</v-icon> -->
          {{dialogObj.title}}
        </v-card-title>
        <v-img
          v-if="dialogObj.images && dialogObj.images.length == 1"
          :src="'/img/' + dialogObj.images[0]"
          max-height="300"
          contain
        ></v-img>
        <v-carousel v-if="dialogObj.images && dialogObj.images.length > 1">
          <v-carousel-item
            v-for="(item,i) in dialogObj.images"
            :key="i"
            :src="'/img/' + item"
            class="test"
          ></v-carousel-item>
        </v-carousel>
        <v-card-text class="mt-4">
          {{dialogObj.detail}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal"
            text
            @click="closeDialog"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    name: 'NewsList',
    data: () => ({
      isActive: false,
      newsList: [],
      dialog: false,
      dialogObj: {
        icon: '',
        title: '',
        detail: '',
        images: ''
      },
    }),
    methods: {
      showDialog(content) {
        this.dialogObj.icon = content.icon;
        this.dialogObj.title = content.title;
        this.dialogObj.detail = content.detail;
        this.dialogObj.images = content.images;
        this.dialog = true;
      },
      closeDialog() {
        this.dialogObj.icon = '';
        this.dialogObj.title = '';
        this.dialogObj.detail = '';
        this.dialogObj.images = '';
        this.dialog = false;
      },
    },
    async mounted() {
      let result = await fetch('https://api.mori-motors.com/news?type=latest').then(async resp => {
        return await resp.json();
      });
      this.newsList = [...result];
    }
  }
</script>
<style scoped>
</style>