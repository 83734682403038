<template>
  <div id="about-us">
    <v-img
      src="/img/about_us.png"
      height="400"
      gradient="to top right, rgb(221,151,55,.33), rgba(72,57,25,.9)"
      dark
      data-aos="zoom-in"
    >
      <v-row
        align="center"
        justify="center"
        style="height:100%;"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <span class="font-poetsen title-text">
            About Us
          </span>
          <br>
          <span class="body-text">
            当工場では、高品質な自動車整備サービスを提供しています。
            <br>
            経験豊富な整備士が最新の設備を使用して、
            <br>
            お客様の車両を迅速かつ丁寧に整備いたします。
            <br>
            英語を話せるスタッフも常駐しており、外国からのお客様にも安心してご利用いただける環境を整えています。
            <br>
            どんな修理やメンテナンスでも、当工場にお任せください。
          </span>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>
<script>
  export default {
    name: 'AboutUs',
    data() {
      return {
        
      }
    },
    mounted() {
      
    }
  }
</script>
<style scoped>
  .title-text {
    font-size: 2rem;
  }
  .body-text {
    font-size: 0.8rem;
    font-weight: 600;
  }
  @media screen and (min-width:480px) { 
    .title-text {
      font-size: 3rem;
    }
    .body-text {
      font-size: 1.4rem;
      font-weight: 200;
    }
  }
</style>