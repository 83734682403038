<template>
  <v-container id="contents-container" class="ma-0 pa-0">
    <div class="sticky" data-aos="zoom-in">
      <span class="ma-4 mt-0 font-poetsen contents-title">Service</span>
      <span class="font-Yusei orange--text text--darken-2 contents-sub-title">サービス</span>
    </div>
    <div class="content-items d-none d-md-flex" data-aos="zoom-in">
      <div
        v-for="(content, i) in contents"
        :key="i"
        class="content-item pa-4"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="400"
              height="360"
            >
              <v-img 
                :src="`/img/${content.img}`"
                height="200px"
              ></v-img>
              <v-card-title>
                {{content.title}}
                <v-icon class="ml-1">{{content.icon}}</v-icon>
              </v-card-title>
              <v-card-subtitle>
                {{content.shortDetail}}
              </v-card-subtitle>
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#585858"
                >
                  <v-btn
                    color="orange"
                    dark
                    @click="showDialog(content)"
                  >
                    詳細
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </div>
    </div>
    <v-carousel
      cycle
      height="400"
      hide-delimiters
      class="d-block d-md-none"
      data-aos="zoom-in"
    >
      <v-carousel-item
        v-for="(content, i) in contents"
        :key="i"
      >
        <v-card
          class="mx-auto"
          max-width="320"
          height="360"
        >
          <v-img 
            :src="`/img/${content.img}`"
            height="200px"
          ></v-img>
          <v-card-title>
            {{content.title}}
            <v-icon class="ml-1">{{content.icon}}</v-icon>
          </v-card-title>
          <v-card-subtitle>
            {{content.shortDetail}}
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="orange"
              dark
              rounded
              @click="showDialog(content)"
            >
              詳細
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-carousel-item>
    </v-carousel>
    <v-dialog
      v-model="dialog"
      width="80%"
    >
      <v-card>
        <v-card-title dark class="text-h5 orange white--text">
          <v-icon dark large>{{dialogObj.icon}}</v-icon>
          {{dialogObj.title}}
        </v-card-title>
        <v-card-text class="mt-4">
          {{dialogObj.detail}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            text
            @click="closeDialog"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    name: 'ContentDescription',
    data: () => ({
      contents: [
        {
          icon: 'mdi-wrench',
          title: '車検整備',
          shortDetail: '日頃のメンテナンスから車検整備まで、お車の整備はお任せください！',
          detail: '当社は、高品質な車検整備サービスを提供することで、お客様の安全と快適なカーライフをサポートしています。車検整備は、車両の安全性や状態を確認し、法令遵守を含めた最適な状態を維持するための重要なプロセスです。当社では、経験豊富なメカニックが最新の設備と技術を駆使して、車両の点検、修理、調整を丁寧に行います。',
          img: 'car2.jpg'
        },
        {
          icon: 'mdi-spray',
          title: '板金塗装',
          shortDetail: 'お車の傷・へこみは気軽にご相談ください。熟練のスタッフが丁寧に修復します。',
          detail: '当社の板金塗装サービスは、車両の外観を美しく修復し、長く快適にご利用いただくためのサポートを提供しています。車両が事故や傷によって損傷を受けた場合や、塗装の劣化が進んでいる場合には、専門の技術と経験豊富なスタッフが丁寧に対応し、車両の美観を回復させます。',
          img: 'car1.jpg'
        },
        {
          icon: 'mdi-car',
          title: '車両販売',
          shortDetail: '新車・中古車の販売も行っております。お求めの車両をお探しいたします。',
          detail: '当社の中古車両販売サービスは、お客様に安心して信頼できる中古車を提供し、快適なカーライフの実現をサポートしています。中古車を購入する際には、安全性や品質、価格などさまざまな要素を考慮する必要がありますが、当社ではそれらの要素を徹底的にチェックし、お客様に満足していただける車両を厳選して販売しています。',
          img: 'jimny.jpg'
        },
      ],
      height: 600,
      dialog: false,
      dialogObj: {
        icon: '',
        title: '',
        detail: ''
      },
    }),
    methods: {
      showDialog(content) {
        this.dialogObj.icon = content.icon;
        this.dialogObj.title = content.title;
        this.dialogObj.detail = content.detail;
        this.dialog = true;
      },
      closeDialog() {
        this.dialogObj.icon = '';
        this.dialogObj.title = '';
        this.dialogObj.detail = '';
        this.dialog = false;
      },
    },
    mounted() {
    }
  }
</script>
<style lang="scss" scoped>
  #contents-container {
    max-width:100vw;
    position: relative;
    // background-color: #ffffff;
    background: linear-gradient(to bottom, #FFFFFF, #FFF3E0);
  }
  // .content-items {
  // }
</style>