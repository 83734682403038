<template>
  <div style="z-index:20;">
    <v-app-bar
      id="top-header"
      app
      elevate-on-scroll
      dark
      scroll-target="#scrolling-techniques-7"
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <!-- <v-toolbar-title class="header-title" style="font-family: 'Monoton', 'Poetsen One', sans-serif;">{{title}}</v-toolbar-title> -->
      <v-toolbar-title class="header-title pa-0">
        <img src="/img/mori_motors_title.svg" alt="/img/mori_motors_title.svg">
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      dark
      color="orange darken-1"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <img src="/img/mori_motors_title.svg" alt="/img/mori_motors_title.svg" style="height:50px;">
          </v-list-item-title>
          <v-list-item-subtitle>
            {{titleJp}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-btn
          v-for="data in icons"
          :key="data"
          icon
          :href="data.href"
        >
          <v-icon>
            {{data.icon}}
          </v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
      <v-list
        nav
        dense
      >
        <v-list-item-group>
          <v-list-item href="/#" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-poetsen">Top</v-list-item-title>
          </v-list-item>
          <v-list-item href="/#news-list" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-timeline-text</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-poetsen">News</v-list-item-title>
          </v-list-item>
          <v-list-item href="/#about-us" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-poetsen">About Us</v-list-item-title>
          </v-list-item>
          <v-list-item href="/#contents-container" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-car</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-poetsen">Service</v-list-item-title>
          </v-list-item>
          <v-list-item href="/#access-map" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-map</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-poetsen">Map</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    name: 'MainHeader',

    data: () => ({
      title: 'mori motors',
      titleJp: '森モータース',
      drawer: false,
      icons: [
        {
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/newmorimotors/'
        },
        // {
        //   icon: 'mdi-instagram',
        //   href: ''
        // },
      ],
    }),
  }
</script>
<style scoped>
  #top-header {
    transition: .3s;
    background-color: #00000000 !important;
  }
  #top-header.recolor {
    background-color: #FB8C00ba !important;
  }
  .header-title img {
    height: 70px;
    margin-top: 6px;
    margin-left: -10px;
  }
  @media screen and (min-width:480px) { 
    .header-title img {
      height: 86px;
      margin-top: 18px;
      margin-left: -10px;
    }
  }
</style>