<template>
  <v-overlay
    :value="overlay"
    color="#FF9D2A"
    :opacity="opacity"
    z-index="30"
    style="transition: 1s;"
    :class="active"
  >
    <div class="d-flex">
      <!--
      poster：動画ファイルが利用できない環境で代替表示される画像
      webkit-playsinline：iOS 9までのSafari用インライン再生指定
      playsinline：iOS 10以降のSafari用インライン再生指定
      muted：音声をミュートさせる
      autoplay：動画を自動再生させる
      loop：動画をループさせる
      controls：コントロールバーを表示する
      -->
      <!-- <video id="video" poster="/img/mori_motors_logo.svg" webkit-playsinline playsinline muted autoplay loop>
        <source src="/img/mori_motors_logo_animation.mp4" type="video/mp4">
      </video> -->

      
      <img src="/img/mori_motors_new_logo_animation.gif" alt="/img/mori_motors_new_logo_animation.gif">


      <!-- <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular> -->
    </div>
  </v-overlay>
</template>
<script>
  export default {
    name: 'ShowLoading',
    data() {
      return {
        overlay: true,
        opacity: 1,
        active: ''
      }
    },
    mounted() {
      setTimeout(() => {
        this.opacity = 0;
        this.active = 'active';
        setTimeout(() => {
          this.overlay = false;
        }, 500);
      }, 2000);
    }
  }
</script>
<style scoped>
  img {
    height: 300px;
    transition: .5s;
  }
  #mask .st0{
    fill:none !important;
    stroke:#fff;
    stroke-width:1px;/*線の太さを指定する*/
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
    stroke-dasharray: 1500; /* 線の間隔を指定する */
    stroke-dashoffset:1500; /* 線の位置を指定する */
  }
  .active img {
    opacity: 0;
  }
</style>